import React from "react"

import Head from "../components/head"
import Layout from "../components/layout"
import baseStyles from "./base.module.scss"
// STEP 1 - INTRO
import Introduction from "./sections/detail/introduction-detail"
import IntroductionSplash from "./sections/splash/introduction-splash"
// STEP 2 - SKILLS
import Skills from "./sections/detail/skills-details"
import SkillsSplash from "./sections/splash/skills-splash"
// STEP 3 - WORK
import Work from "./sections/detail/work-details"
import WorkSplash from "./sections/splash/work-splash"
// STEP 4 - PROJECTS

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideSplash: "0",
      fadeTop: false,
    }
  }

  listenScrollEvent = scrollItem => {
    this.setState({
      hideSplash: Math.round(
        (document.getElementById("containerInner").getBoundingClientRect().top /
          scrollItem.offsetHeight) *
          -1
      ),
    })
  }

  componentDidMount() {
    const scrollContainer = document.getElementById("scroll")
    scrollContainer.addEventListener("scroll", () =>
      this.listenScrollEvent(scrollContainer)
    )
  }

  render() {
    return (
      <>
        <Head title="Home" />
        <Layout>
          <div className={baseStyles.wrapper}>
            <div className={baseStyles.container}>
              <ul
                className={`${baseStyles.containerInner} ${
                  baseStyles[`hide${this.state.hideSplash}`]
                }`}
                id="scroll"
              >
                <li className={baseStyles.listItem}>
                    <IntroductionSplash />
                </li>
                <li  className={baseStyles.listItem} id="containerInner">
                    <Introduction />
                </li>
                <li className={baseStyles.listItem}>
                    <WorkSplash />
                </li>
                <li className={baseStyles.listItem}>
                    <Work />
                </li>
                <li className={baseStyles.listItem}>
                    <SkillsSplash />
                  </li>
                <li className={baseStyles.listItem}>
                    <Skills />
                </li>
              </ul>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default IndexPage
