import React, { useContext } from "react"
import workHistory from "../../../data/work-history.json"
import { WorkplaceContext } from "../../../context/workplace"

import styles from "./base-details.module.scss"
import workStyles from "./work.module.scss"

export const Work = () => {
  const { setWorkplace, state: { workplace = 2 } = {} } =
    useContext(WorkplaceContext) || {}

  return (
    <div className={styles.container}>
      {workplace > 0 && (
        <button
          className={workStyles.navigatePrev}
          onClick={() => setWorkplace(workplace - 1)}
        >
          {"<"}
        </button>
      )}
      {workplace < 2 && (
        <button
          className={workStyles.navigateNext}
          onClick={() => setWorkplace(workplace + 1)}
        >
          {">"}
        </button>
      )}
      <h2 className={workStyles.title}>{workHistory[workplace].title}</h2>
      <h5 className={workStyles.date}>{workHistory[workplace].date}</h5>
      <div className={workStyles.container}>
        <div
          className={workStyles[workHistory[workplace].title.replace(" ", "")]}
        >
          {workHistory.map(wp => (
            <div className={workStyles.section} key={`${wp.key}_text`}>
              <p>{wp.content}</p>
              <a href={wp.link} alt={wp.alt}>
                Read more about {wp.title}
                <br/><br/>
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className={workStyles.timeline}>
        {workHistory.map((individualWorkplace, index) => {
          let buttonStyles = workStyles.button
          if (workplace === index) {
            buttonStyles = `${workStyles.selected} ${workStyles.button}`
          }
          return (
            <button
              key={`${individualWorkplace.key}_button`}
              onClick={() => setWorkplace(index)}
              className={buttonStyles}
            >
              <div className={workStyles[`${individualWorkplace.key}_logo`]}/>
              <div className={workStyles.timelineItem}>
                {individualWorkplace.date}
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default Work
